

.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}


.App-header {
  background-color: white;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Carousel-div {
  padding-left: 15vh;
  padding-right: 15vh;
  padding-bottom: 5vh;
}

.App-link {
  color: blue;
}
